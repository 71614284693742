import { CabIcon } from "@CabComponents/CabIcon";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { ReactElement } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";

export interface InfoToolTipProps {
  message: string;
  fontSize?: number;
}

const InfoToolTip = ({message, fontSize = 20}: InfoToolTipProps): ReactElement => {
  return (
    <CabTooltip
      title={message}
      placement="bottom"
      wrapWithSpan
    >
      <CabIcon Icon={IoInformationCircleOutline} color='secondary' sx={{ fontSize }}/>
    </CabTooltip>
  );
};

export default InfoToolTip;